import PromoContact from "./EN/Contact_Components/PromoContact";
import Container from "./EN/Contact_Components/Container";
export default function Contact() {
    return (
        <>
            <PromoContact />
            <Container />
        </>
    );
}
