import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import React from "react";

/* Routers */
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
/* Css Files */
import "./Css/animate.css";
import "./Css/bootstrap.css";
// import "./Css/font-awesome.min.css";
import "./style.css";
import "./Css/fonts.css";
import "./Css/responsive.css";
import "./Css/color/color.css";
import "./AWDSMainRU.css";
import "./AWDSMainAR.css";
/* LOGO */
import Logo from "./images/logo.svg";
import FooterLogo from "./images/logo-footer.svg";
/* Pages */
import Main from "./Components/Pages/Main";
// import MainAR from "./Components/Pages/MainAR";
import Contact from "./Components/Pages/Contact";

/* Components */
import Header from "./Components/Header/Header";
// import HeaderAR from "./Components/Header/HeaderAR";
import Footer from "./Components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function App() {
    const [t, i18n] = useTranslation();
    document.title = t("page.title");
    var selectMinu_ab = document.getElementById("SelectLang-absolute");
    const [lang, setLang] = useState();
    useEffect(() => {
        if (!window.localStorage.getItem("Display_Lang")) {
            window.localStorage.setItem("Display_Lang", "en");
            document.body.removeAttribute("ïd");
            // let select = document.getElementById("SelectLang");
            selectMinu_ab.value = "en";
        } else {
            let select = document.getElementById("SelectLang-absolute");
            setLang(window.localStorage.getItem("Display_Lang"));
            select.value = window.localStorage.getItem("Display_Lang");
            i18n.changeLanguage(select.value);
            if (window.localStorage.getItem("Display_Lang") === "ar") {
                document.body.setAttribute("id", "ar");
            }
            if (window.localStorage.getItem("Display_Lang") === "ru") {
                document.body.setAttribute("id", "ru");
            }
        }
    }, []);
    return (
        <div className="App">
            <div id="pre-loader" className="loader-container">
                <div className="loader">
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div id="wrapper">
                <Header Logo={Logo} />
                {/* <HeaderAR Logo={Logo} /> */}
                <Router>
                    <Routes>
                        <Route path="/" element={<Main />} />
                        <Route path="/order" element={<Contact />} />
                    </Routes>
                </Router>
                {/* <Main /> */}
                {/* <MainAR /> */}
                <Footer Footer_Logo={FooterLogo} />
                <span id="back-top">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                    >
                        <path d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z" />
                    </svg>
                </span>
                <select
                    id="SelectLang-absolute"
                    className="LangSelect-absolute"
                    onChange={() => {
                        switch (selectMinu_ab.value) {
                            default:
                                i18n.changeLanguage("en");
                                document.body.removeAttribute("id");
                                localStorage.setItem(
                                    "Display_Lang",
                                    selectMinu_ab.value
                                );

                                break;
                            case "ar":
                                i18n.changeLanguage("ar");
                                document.body.setAttribute("id", "ar");
                                localStorage.setItem(
                                    "Display_Lang",
                                    selectMinu_ab.value
                                );
                                break;
                            case "en":
                                i18n.changeLanguage("en");
                                document.body.removeAttribute("id");
                                localStorage.setItem(
                                    "Display_Lang",
                                    selectMinu_ab.value
                                );
                                break;
                            case "ru":
                                i18n.changeLanguage("ru");
                                document.body.removeAttribute("id");

                                document.body.setAttribute("id", "ru");
                                localStorage.setItem(
                                    "Display_Lang",
                                    selectMinu_ab.value
                                );

                                break;
                        }
                    }}
                >
                    <option className="LangSelect_Option" value={"ar"}>
                        AR
                    </option>
                    <option className="LangSelect_Option" value={"en"}>
                        EN
                    </option>
                    <option className="LangSelect_Option" value={"ru"}>
                        RU
                    </option>
                </select>
            </div>
        </div>
    );
}

export default App;
